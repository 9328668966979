import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { createAuthorization, deleteAuthorization, listAuthorizations, listClients, listEmployees, updateClient } from "../utils/api";
import { Client } from '../model/Client';
import { Employee } from "../model/Employee";
import { Authorization } from "../model/Authorization";
import EmptyList from "../components/EmptyList";
import { useVerticalScrollWithShadow } from "../hooks/VerticalScrollWithShadow";
import Select from "../components/Select";
import { getCognitoStatus } from "../utils/cognito-utils";

const possibleStatus = ['delete_account', 'enabled', 'ignored'];

export default function Management(): JSX.Element {
    const [loading, setLoading] = useState(true);
    const { ref: coachRef, boxShadow: coachBoxShadow, onScrollHandler: onScrollCoachHandler } = useVerticalScrollWithShadow();
    const { ref: clientRef, boxShadow: clientBoxShadow, onScrollHandler: onScrollClientHandler } = useVerticalScrollWithShadow();
    const { ref: authorizationRef, boxShadow: authorizationBoxShadow, onScrollHandler: onScrollAuthorizationHandler } = useVerticalScrollWithShadow();
    const [clients, setClients] = useState<Client[]>([]);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [authorizations, setAuthorizations] = useState<Authorization[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
    const [selectedClient, setSelectedClient] = useState<Client>();
    const [del, setDel] = useState(false);

    const setUp = useCallback(async () => {
        setLoading(true);
        setDel(false);
        setSelectedEmployee(undefined);
        setSelectedClient(undefined);
        try {
            await Promise.all([
                listClients(true).then(setClients),
                listEmployees().then(setEmployees),
                listAuthorizations().then(setAuthorizations)
            ]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => { setUp() }, [setUp]);

    function handleSelectChange(event: SyntheticEvent, client: Client) {
        const target = event.target as HTMLInputElement;
        setLoading(true);
        updateClient(client.id ?? '', {
            ...client,
            [target.name]: target.value
        }).then(setUp);
    }

    const selectedAuthorizations = authorizations
        .filter(a => selectedEmployee === undefined || selectedEmployee.id === a.employee_id)
        .filter(a => selectedClient === undefined || selectedClient.id === a.client_id);

    const emptyTable = selectedAuthorizations.length === 0;

    const proposeDelete = selectedAuthorizations.length === 1;

    const proposeCreation = emptyTable && selectedEmployee !== undefined && selectedClient !== undefined;

    function confirmDeleteAuthorization() {
        setLoading(true);
        deleteAuthorization(selectedAuthorizations[0].employee_id ?? '', selectedAuthorizations[0].client_id ?? '').then(setUp);
    }

    function createNewAuthorization(authorization: Authorization) {
        setLoading(true);
        createAuthorization(authorization).then(setUp);
    }

    if (loading) {
        return (
            <main className='fallback-page'>
                <Loading />
            </main>
        )
    }

    clients.sort((a, b) => {
        if (a.creation_timestamp === undefined) return 1;
        if (b.creation_timestamp === undefined) return -1;

        if (a.creation_timestamp > b.creation_timestamp) return -1;
        if (a.creation_timestamp < b.creation_timestamp) return 1;

        return 1;  // Defualt return
    });

    return (
        <main className="flex flex-col">
            <div className={`${!del && 'hidden'} fixed w-full h-full -ml-4 lg:-ml-64 -mt-20 bg-black opacity-30`} />
            <div className={`${!del && 'hidden'} fixed w-full h-full flex flex-full justify-center -ml-4 lg:-ml-64 -mt-32 p-8`}>
                <article className="self-center w-fit p-3">
                    <h2>
                        ¿Estás seguro que deseas eliminar esta autorización?
                    </h2>
                    <div className="flex justify-end space-x-4 w-full">
                        <button className="button-secondary border-0" onClick={() => setDel(false)}>
                            Cancelar
                        </button>
                        <button className="button-secondary-red border-0" onClick={confirmDeleteAuthorization}>
                            Eliminar
                        </button>
                    </div>
                </article>
            </div>

            <div className="flex gap-8 w-full">
                <article className="p-0 h-fit w-full bg-sf-violet-light">
                    <h2 className="p-3 pb-0">Equipo de coaching</h2>
                    <div onScroll={onScrollCoachHandler} ref={coachRef} className={`max-h-96 rounded-b-2xl overflow-auto ${coachBoxShadow}`}>
                        {employees.map((employee, index) => <div key={index} className={`flex justify-between items-center p-3 m-3 cursor-pointer rounded-lg bg-sf-white ${employee === selectedEmployee ? 'ring-2 ring-inset ring-sf-violet-dark' : ''}`} onClick={() => employee === selectedEmployee ? setSelectedEmployee(undefined) : setSelectedEmployee(employee)}>
                            <h3>{employee.email}</h3>
                            <p>{employee.type}</p>
                        </div>)}
                    </div>
                </article>
                <article className="p-0 h-fit w-full bg-sf-violet-light">
                    <h2 className="p-3 pb-0">Clientes actuales</h2>
                    <div onScroll={onScrollClientHandler} ref={clientRef} className={`max-h-96 rounded-b-2xl overflow-auto ${clientBoxShadow}`}>
                        <ul className="divide-y divide-sf-black border-t border-sf-black">
                            {possibleStatus.map(status => (
                                <div className="p-3">
                                    <h3>Clientes en estado: {status}</h3>
                                    {clients.filter(client => client.status === status).length > 0 ?
                                        clients.filter(client => client.status === status).map((client, index) => (
                                            <div key={index} className='flex items-center'>
                                                <div className={`w-full p-3 mt-3 mr-3 cursor-pointer rounded-lg h-11 flex flex-col justify-center bg-sf-white ${client === selectedClient ? 'ring-2 ring-inset ring-sf-violet-dark' : ''}`} onClick={() => client === selectedClient ? setSelectedClient(undefined) : setSelectedClient(client)}>
                                                    <div className="flex w-full justify-between">
                                                        <h3>{client.email}</h3>
                                                        <p>{getCognitoStatus(client.cognito_status ?? '')}</p>
                                                    </div>
                                                    <div className="flex w-full justify-between">
                                                        <small>{client.name ?? 'Sin nombre asignado'}</small>
                                                        <small>{client.creation_timestamp ? new Date(parseInt(client.creation_timestamp)).toLocaleDateString('es', { timeZone: 'UTC' }) : 'Fecha de creación desconocida'}</small>
                                                    </div>
                                                </div>
                                                <div className='w-52'>
                                                    <Select placeholder='Estado' name='status' value={client.status} onChange={e => { handleSelectChange(e, client) }} options={possibleStatus} />
                                                </div>
                                            </div>
                                        ))
                                        : <EmptyList message={`No hay clientes en estado ${status}.`} />
                                    }
                                </div>
                            ))}
                        </ul>
                    </div>
                </article>
            </div>

            <article className="w-full">
                <h2>Autorizaciones</h2>
                {!emptyTable && <div className="flex-col divide-y divide-sf-black rounded-2xl ring-1 ring-sf-black">
                    <div className="flex basis-full bg-sf-gray-extra-light rounded-t-2xl divide-x divide-sf-black">
                        <div className="basis-1/3 flex justify-center items-center text-sm font-bold h-11">Coach</div>
                        <div className="basis-1/3 flex justify-center items-center text-sm font-bold h-11">Cliente</div>
                        <div className="basis-1/3 flex justify-center items-center text-sm font-bold h-11">Permisos</div>
                    </div>

                    <div onScroll={onScrollAuthorizationHandler} ref={authorizationRef} className={`max-h-96 rounded-b-2xl overflow-auto ${authorizationBoxShadow}`}>
                        <ul className="divide-y divide-sf-black">
                            {selectedAuthorizations.map((authorization, index) => <div key={index} className='flex basis-full divide-x divide-sf-black' >
                                <div className="basis-1/3 text-center truncate">
                                    <EmployeeItem employee={employees.filter(e => e.id === authorization.employee_id)[0]} />
                                </div>
                                <div className="basis-1/3 text-center truncate">
                                    <ClientItem client={clients.filter(e => e.id === authorization.client_id)[0]} />
                                </div>
                                <div className="basis-1/3 text-center truncate">
                                    <PermissionsItem permissions={authorization.permissions ?? []} />
                                </div>
                            </div>
                            )}
                        </ul>
                    </div>
                </div>}
                {proposeDelete && <div className="flex w-full justify-center">
                    <div className="button-secondary-red" onClick={() => setDel(true)}>Eliminar</div>
                </div>}
                {proposeCreation && <div className="flex w-full justify-center">
                    <NewAuthorization employee={selectedEmployee} client={selectedClient} createNewAuthorization={createNewAuthorization} />
                </div>}
                {emptyTable && !proposeCreation && <EmptyList message="Selecciona un coach y un cliente para asignar permisos" />}
            </article>
        </main>
    )
}

interface EmployeeItemProps {
    employee: Employee
}

function EmployeeItem(props: EmployeeItemProps): JSX.Element {
    return (
        <div className="text-left px-2 py-1 flex flex-col justify-evenly h-11">
            <p className="font-bold">{props.employee.email}</p>
            <small>{props.employee.type}</small>
        </div>
    )
}

interface ClientItemProps {
    client: Client
}

function ClientItem(props: ClientItemProps): JSX.Element {
    return (
        <div className="text-left px-2 py-1 flex flex-col justify-evenly h-11">
            <p className="font-bold">{props.client?.email}</p>
            <small>{props.client?.name}</small>
        </div>
    )
}

interface PermissionsItemProps {
    permissions: string[]
}

function PermissionsItem(props: PermissionsItemProps): JSX.Element {
    return (
        <div className="text-left px-2 py-1 flex flex-wrap items-center justify-center h-11">
            {props.permissions.map((permission, index) => (
                <div key={index} className="bg-sf-violet-dark px-3 py-0.5 m-1 rounded-full text-sm flex justify-center items-center text-sf-white font-bold">
                    {permission}
                </div>
            ))}
        </div>
    )
}

interface NewAuthorizationProps {
    employee: Employee;
    client: Client;
    createNewAuthorization: (autorization: Authorization) => void
}

const allPermissions = ['create', 'read', 'update', 'delete'];

function NewAuthorization(props: NewAuthorizationProps): JSX.Element {
    const [selectedPermissions, setSelectedPermissions] = useState<string[]>(allPermissions);

    function selectPermission(permission: string) {
        setSelectedPermissions(oldSelectedPermissions => {
            if (oldSelectedPermissions.includes(permission)) {
                return oldSelectedPermissions.filter(oldPermission => oldPermission !== permission);
            } else {
                return [...oldSelectedPermissions, permission];
            }
        })
    }

    function handleCreate() {
        props.createNewAuthorization({
            employee_id: props.employee.id,
            client_id: props.client.id,
            permissions: selectedPermissions
        });
    }

    return (
        <div className="flex flex-col justify-center items-center w-fit h-fit">
            <div className="p-3 w-96 flex flex-col items-center gap-2 bg-sf-violet-dark text-sf-white rounded-lg">
                <h3 className="text-base">Nueva asignación</h3>
                <p className="text-base">Coach: {props.employee.email}</p>
                <p className="text-base">Cliente: {props.client.email}</p>
                <h3 className="text-base">Permisos</h3>
                <div className="flex flex-wrap justify-between">
                    {allPermissions.map(permission => <div key={permission} className="flex items-center space-x-1 m-2 cursor-pointer" onClick={() => { selectPermission(permission) }}>
                        <p>{permission}</p>
                        <div className="h-4 w-4 rounded-sm bg-sf-white flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" className={`w-full text-sf-violet-dark ${selectedPermissions.includes(permission) ? '' : 'hidden'}`} viewBox="0 0 24 24"><path fill="currentColor" d="m10 13.6l5.9-5.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-6.6 6.6q-.3.3-.7.3t-.7-.3l-2.6-2.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l1.9 1.9Z" /></svg>
                        </div>
                    </div>)}
                </div>
            </div>
            <div className='button-secondary mt-2' onClick={handleCreate}>Crear</div>
        </div>
    )
}