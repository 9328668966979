export function getCognitoStatus(status: string): string {
    switch (status) {
        case 'UNCONFIRMED':
            return 'Email & Password, no confirmado';
        case 'CONFIRMED':
            return 'Email & Password, confirmado';
        case 'EXTERNAL_PROVIDER':
            return 'Google OAuth 2.0';
        default:
            return 'Autenticación desconocida';
    }
}
